import React, { useState } from 'react';
import './../../assets/css/ExploreSection.css';

const ExploreSection = () => {
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      Title: email,
      Body: `Name: ${email}\nSubbed to waitlist`,
      Email: email,
    };

    try {
      const response = await fetch('https://tribalcamping.co/api/Notification/send-notification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setNotification('You have successfully joined the waitlist!');
        setEmail(''); // Clear the email field
      } else {
        setNotification('Failed to join the waitlist. Please try again.');
      }
    } catch (error) {
      setNotification('An error occurred. Please try again later.');
      console.error('Error:', error);
    }
  };

  return (
    <section className="explore-section afacad-flux-wild">
      <div className="explore-content">
        <h2>Your Exploration Starts Here</h2>
        <p>
          Got questions or stories to share? <br />
          Contact us and let's make your next <br />
          adventure even better!
        </p>
        <hr className="explore-divider" />
        <div className="explore-info">
          <span>contact@tribalcamping.co</span>
        </div>
        <div className="waitlist-section">
          <h3>Be the First to Know!</h3>
          <p style={{color: "black"}}>Join our waitlist to stay updated on the latest adventures and features.</p>
          <form className="waitlist-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              className="waitlist-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="waitlist-button">
              Join the Waitlist
            </button>
          </form>
          {notification && (
            <div className="notification-banner">
              <p>{notification}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ExploreSection;
