import React from 'react';
import './../../assets/css/AdventureSection.css';
import bear from './../../images/bear.png';
import story from './../../images/story.png';

const AdventureSection = () => {

  const handleScrollDown = () => {
    const statsSection = document.querySelector('.stats-section');
    if (statsSection) {
      statsSection.scrollIntoView({
        behavior: 'smooth',  // Glatko pomeranje
        block: 'start'       // Pomeranje tako da sekcija bude na vrhu ekrana
      });
    }
  };
  
  return (
    <section className="adventure-section afacad-flux-wild">
      <div className="wildlife-section">
        <div className="wildlife-content">
          <div className="wildlife-description">
            <h2>Tribal App</h2>
            <p>
            Tribal is a specialized search engine that helps users discover wild camping spots across Europe, perfect for adventurers seeking untouched nature. Beyond locating hidden camping gems, Tribal offers additional services like real-time weather updates and wildlife information, making it an essential tool for planning outdoor escapes.
            </p>
            <p>
            Whether you're looking for a secluded spot in the mountains or by a remote lake, Tribal ensures you’re informed about your surroundings and ready for your next adventure.
            </p>
            <div className="button-container">
  <button className="show-animals-btn">
    <i class="android-btn-icon fab fa-android"></i>Coming soon
  </button>
  <button className="show-animals-btn">
    <i class="ios-btn-icon fab fa-apple"></i>Coming soon
  </button>
</div>

  
          </div>

          <div className="wildlife-image">
            <img 
              src={bear}
              alt="Wildlife Bear" 
              className="wildlife-bear"
            />
          </div>
        </div>

       

        {/* New Button Section */}
        <div className="special-button-section">
          <div className="circular-button" onClick={handleScrollDown}>
            <i className="down-arrow-icon" style={{fontStyle:"normal"}}>↓</i>
          </div>
          <p className="button-label">Why We're Special?</p>
        </div>
      </div>
    </section>
  );
};

export default AdventureSection;
