import React, { useState, useEffect } from 'react';
import './../assets/css/Navbar.css';
import icon from '../assets/images/tribalIcon.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDarkBackground, setIsDarkBackground] = useState(true);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true); // Track navbar visibility
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY); // Track the previous scroll position

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    // Show the navbar when scrolling up, hide when scrolling down
    if (currentScrollPos < prevScrollPos || currentScrollPos <= 0) {
      setIsNavbarVisible(true);
    } else {
      setIsNavbarVisible(false);
    }

    // Handle dark/light background based on specific section scroll position
    const thingsToDoDiv = document.querySelector('.things-to-do');
    if (thingsToDoDiv) {
      const divTop = thingsToDoDiv.getBoundingClientRect().top;
      setIsDarkBackground(divTop > 0);
    }

    // Update the previous scroll position
    setPrevScrollPos(currentScrollPos);
  };

  const scrollTo = (targetClass) => {
    const targetSection = document.querySelector(targetClass);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <nav
      className={`navbar ${isDarkBackground ? 'dark' : 'light'} ${isNavbarVisible ? 'navbar-visible' : 'navbar-hidden'
        }`}
      style={isDarkBackground ? {

      } : { backgroundColor: 'white' }}
    >
      <div className="navbar-logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={icon} style={{ width: 80, height: 80 }} alt="Tribal Camping" />
        <a href="/" className="afacad-flux-header" style={isDarkBackground ? {
          textShadow: "2px 2px 4px black"
        } : {
        }}>Tribal Camping</a>
      </div>

      <div className={`navbar-links afacad-flux-header ${menuOpen ? 'active' : ''} ${isDarkBackground ? 'dark-links' : 'light-links'}`} >
        <a href="#about-us" onClick={() => scrollTo('.adventure-section')}>About Us</a>
        <a href="#places" onClick={() => scrollTo(".wildlife-section-hero")}>Places</a>
        <a href="#app" onClick={() => scrollTo(".things-to-do")}>App Features</a>
        <a href="#faq" onClick={() => scrollTo(".faq-section")}>FAQ</a>
        <a href="#download" onClick={() => scrollTo('.download-section')}>Download</a>
      </div>

      <div className={`navbar-toggle ${menuOpen ? 'active' : ''} ${isDarkBackground ? 'dark-toggle' : 'light-toggle'}`} onClick={toggleMenu}>
        <span className="burger"></span>
        <span className="burger"></span>
        <span className="burger"></span>
      </div>
    </nav>
  );
};

export default Navbar;
