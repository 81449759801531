import React from 'react';
import './../../assets/css/DownloadSection.css';
import { FaApple, FaAndroid } from 'react-icons/fa'; // Icons for iOS and Android

const DownloadSection = () => {
  return (
    <div className="download-section afacad-flux-wild">
      <h1>Download the Tribal Camping App</h1>
      <p>Available for both iOS and Android, download the Tribal Camping app today and start exploring the best campsites near you!</p>
      <div className="button-container">
        <a href="#" className="download-button ios-btn">
          <FaApple className="icon" /> Coming soon
        </a>
        <a href="#" className="download-button android-btn">
          <FaAndroid className="icon" /> Coming soon
        </a>
      </div>
    </div>
  );
};

export default DownloadSection;
